<template>
  <div class="v-input v-input--horizontal v-input--density-comfortable v-text-field">
    <div class="v-input__control">
      <div class="v-field v-field--active v-field--no-label v-field--variant-outlined v-theme--ORANGE_THEME">
        <div class="v-field__field">
          <VueDatePicker v-model="datePicker"
                         class="vue-data-picker"
                         range multi-calendars
                         menu-class-name="dp-custom-menu"
                         input-class-name="dp-custom-input"
                         locale="ua"
                         text-input
                         :auto-position="true"
                         :day-class="getDayClass"
                         :partial-range="false"
                         @update:model-value="handleDate">
          </VueDatePicker>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import {addDays, isEqual, set} from 'date-fns';

import {mapActions, mapState} from "pinia";
import {useDashboardStore} from "../../stores/dashbordStore";

export default {
  name: 'DateField',
  components: {
    VueDatePicker
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    countDay: {
      type: Number,
      default: 7,
    },
  },
  data() {
    return {
      date: new Date(),
    };
  },
  computed: {
    ...mapState(useDashboardStore, ['datePicker']),
  },
  methods: {
    ...mapActions(useDashboardStore, ['resetDate']),
    getDayClass() {
      let date = new Date();
      if (isEqual(date, addDays(set(new Date(), {hours: 0, minutes: 0, seconds: 0, milliseconds: 0}), 1)))
        return 'marked-cell';
      return '';
    },
    handleDate(modelData) {
      const inputDateStart = new Date(modelData[0]);
      const dateStart = this.formatDate(inputDateStart, true);

      const inputDateEnd = new Date(modelData[1]);
      const dateEnd = this.formatDate(inputDateEnd, false);

      if (this.payload !== null) {
        const payload = {
          event: 'date',
          type: this.type,
          listSelected: null,
          dateStart: dateStart,
          dateEnd: dateEnd
        };

        this.$emit('selected-date', payload);
      }
    },
    formatDate(date, startTime) {
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = date.getDate().toString().padStart(2, '0');

      let hours = date.getHours().toString().padStart(2, '0');
      let minutes = date.getMinutes().toString().padStart(2, '0');
      let seconds = date.getSeconds().toString().padStart(2, '0');
      if (startTime) {
        hours = '00';
        minutes = '00';
        seconds = '00';
      } else {
        hours = '23';
        minutes = '59';
        seconds = '59';
      }

      return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
    }
  },
  mounted() {
    this.resetDate(this.countDay);
  }
};
</script>

<style lang="scss">
.dp__menu {
  top: auto !important;
  left: 0px !important;
  transform: unset !important;
}


.dp-custom-menu {
  box-shadow: 0 0 6px #fb9678;
  top: 45px !important;
  z-index: 99999999999999 !important;
}

.dp-custom-input {
  box-shadow: 0 0 2px #f8f9fa;
  background: #f8f9fa !important;
  font-family: Source Sans Pro, sans-serif;
  font-size: 16px !important;
  color: #212529 !important;
  white-space: nowrap !important;
  display: inline-flex !important;
  align-items: center !important;
  justify-content: space-between !important;

  &:hover {
    border-color: #fb9678;
  }
}

.dp__cell_inner {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: var(--vc-text-sm);
  font-weight: var(--vc-font-medium);
  width: 28px;
  height: 28px;
  line-height: 28px;
  border-radius: var(--vc-rounded-full);
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  cursor: pointer;
}

.dp__range_between {
  background: #a1eb96;
  border-radius: var(--vc-rounded-full);
  border: 1px solid var(--dp-hover-color);
}

.dp__action_button {
  height: 27px;
}

.vue-data-picker {
  position: relative;
  opacity: 1;
}

.v-input__control .v-field {
  color: inherit;
  flex-wrap: wrap;
  letter-spacing: .009375em;
  opacity: var(--v-high-emphasis-opacity);
}

.dp__input_wrap .dp__input {
  cursor: pointer;
  border-radius: 7px;
}
.dp__action_select{
  font-size: 14px;
  font-weight: 500;
  font-family: "DM Sans", sans-serif;
  line-height: 24px;
  text-decoration: none solid rgb(255, 255, 255);
  text-align: center;
  text-transform: capitalize;
  white-space: nowrap;
  word-spacing: 0px;
  min-width: 75px;
  max-width: 100%;
  height: 36px !important;
  padding: 0 16px 0 16px;
}
.dp__action_cancel{
  font-size: 14px;
  font-weight: 500;
  font-family: "DM Sans", sans-serif;
  line-height: 24px;
  text-decoration: none solid rgb(255, 255, 255);
  text-align: center;
  text-transform: capitalize;
  white-space: nowrap;
  word-spacing: 0px;
  min-width: 75px;
  max-width: 100%;
  height: 36px !important;
  padding: 0 16px 0 16px;
  background-color: #03c9d7;
  color: #FFFFFF;
}
.dp__theme_light {
  --dp-background-color: #ffffff;
  --dp-text-color: #212121;
  --dp-hover-color: #f3f3f3;
  --dp-hover-text-color: #212121;
  --dp-hover-icon-color: #959595;
  --dp-primary-color: #fb9678;
  --dp-primary-text-color: #f8f5f5;
  --dp-secondary-color: #c0c4cc;
  --dp-border-color: #ddd;
  --dp-menu-border-color: #ddd;
  --dp-border-color-hover: #aaaeb7;
  --dp-disabled-color: #f6f6f6;
  --dp-scroll-bar-background: #f3f3f3;
  --dp-scroll-bar-color: #959595;
  --dp-success-color: #76d275;
  --dp-success-color-disabled: #a3d9b1;
  --dp-icon-color: #959595;
  --dp-danger-color: #ff6f60;
  --dp-highlight-color: rgba(25, 118, 210, 0.1);
}

.root{
  --dp-button-heigh: 35px; /*Size for buttons in overlays*/
}
</style>