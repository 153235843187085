<template>
  <div class="v-col-sm-2"
       style="cursor: pointer"
       @click="handleClick(type)">
    <div class="v-theme--ORANGE_THEME v-card--density-default elevation-10 rounded-md v-card--variant-elevated">
      <div class="v-card__loader">
        <div class="v-progress-linear v-theme--ORANGE_THEME"
             role="progressbar"
             aria-valuemin="0"
             aria-valuemax="100"
             style="height: 0px; --v-progress-linear-height:2px;">
          <div class="v-progress-linear__background"
               style="width: 100%;"></div>
          <div class="v-progress-linear__indeterminate">
            <div class="v-progress-linear__indeterminate long"></div>
            <div class="v-progress-linear__indeterminate short"></div>
          </div>
        </div>
      </div>
      <div class="grid-container">
        <div class="grid-item">
          <div class="d-flex align-start">
            <span class="text-subtitle-1 font-weight-medium text-medium-emphasis">{{ $t(title) }}</span>
            <div class="flex-grow-1"></div>
            <div class="ml-auto">
              <button v-if="type==='sales'"
                      type="button"
                      class="v-btn--icon v-theme--ORANGE_THEME bg-inherit v-btn--density-default v-btn--size-default">
                <img src="@/assets/icons/dashboard/sales.svg" alt="user" width="25">
              </button>
              <button v-if="type==='write-off'"
                      type="button"
                      class="v-btn--icon v-theme--ORANGE_THEME bg-inherit v-btn--density-default v-btn--size-default">
                <img src="@/assets/icons/dashboard/write-off.svg" alt="user" width="25">
              </button>
              <button v-if="type==='cost'"
                      type="button"
                      class="v-btn--icon v-theme--ORANGE_THEME bg-inherit v-btn--density-default v-btn--size-default">
                <img src="@/assets/icons/dashboard/cost.svg" alt="user" width="30">
              </button>
              <button v-if="type==='income'"
                      type="button"
                      class="v-btn--icon v-theme--ORANGE_THEME bg-inherit v-btn--density-default v-btn--size-default">
                <img src="@/assets/icons/dashboard/income.svg" alt="user" width="20">
              </button>
              <button v-if="type==='remaining'"
                      type="button"
                      class="v-btn--icon v-theme--ORANGE_THEME bg-inherit v-btn--density-default v-btn--size-default">
                <img src="@/assets/icons/dashboard/remaining.svg" alt="user" width="25">
              </button>
              <button v-if="type==='checks'"
                      type="button"
                      class="v-btn--icon v-theme--ORANGE_THEME bg-inherit v-btn--density-default v-btn--size-default">
                <img src="@/assets/icons/dashboard/checks.svg" alt="user" width="25">
              </button>
              <button v-if="type==='average'"
                      type="button"
                      class="v-btn v-btn--elevated v-btn--icon v-theme--ORANGE_THEME bg-white v-btn--density-default v-btn--size-default"
                      depressed=""
                      small="">
                <img src="@/assets/icons/dashboard/average.svg" alt="user" width="25">
              </button>
              <!-- <button v-if="type==='clients'"
                      type="button"
                      class="v-btn v-btn--elevated v-btn--icon v-theme--ORANGE_THEME bg-white v-btn--density-default v-btn--size-default"
                      depressed=""
                      small="">
                <img src="@/assets/icons/dashboard/average.svg" alt="user" width="25">
              </button> -->
            </div>
          </div>
          <h4 class="text-h4 font-weight-regular mt-1">
            {{ indicator }} {{ currency }}
          </h4>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Indicator",
  props: {
    type: {
      type: String,
      required: false,
    },
    title: {
      type: String,
      required: true,
    },
    indicator: {
      type: Number,
      required: true,
    },
    currency: {
      type: String,
      required: false,
    },
  },
  inject: [],
  components: {},
  data() {
    return {};
  },
  computed: {},
  methods: {
    handleClick(type) {
      console.log("handleClick", type);
      this.$emit("event-indicator-click", type);
    }
  },
  created() {
  },
  mounted() {
  },
};
</script>

<style scoped>
.font-weight-medium{
  font-weight: 800 !important;
}
.text-subtitle-1 {
  font-size: 1.2rem !important;
}
.v-col-sm-2 {
  flex: 0 0 14.28%;
  max-width: 16.6666666667%;
}



.v-row {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); /* Розміщення елементів в рядках і стовпцях */
  grid-gap: 10px; /* Простір між елементами */
}

.v-col-sm-2 {
  width: 100%; /* Забезпечує 100% ширини на більших екранах */
}

@media (min-width: 768px) {
  .v-col-sm-2 {
    max-width: calc(33.33% - 10px); /* Максимальна ширина елементу на більших екранах */
  }
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-gap: 10px;
  user-select: none;

  border-style: solid;
  border-color: #e2e8f0;
}

.grid-item {
  padding: 25px;
  text-align: left;
  user-select: none;
}
</style>
