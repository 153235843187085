<template>
  <div>
    <DatePicker
      v-model.string="value"
      is24hr
      hide-time-header
      :input-debounce="500"
      mode="dateTime"
      :masks="masks"      
    >    
      <template #default="{ togglePopover, inputValue,  }">
        <div
          class="flex border-solid form-border-width-input form-shadow-input form-border-color-input hover:form-bg-input-hover hover:form-color-input-hover hover:form-border-color-input-hover hover:form-shadow-input-hover focused:form-bg-input-focus focused:form-color-input-focus focused:form-border-color-input-focus focused:form-shadow-input-focus focused:form-ring focused-hover:form-shadow-input-hove form-radius-input form-h-input-height"
          :class="{'disabled': fieldDisabled}"
        >
          <a
            class="flex justify-center items-center px-2 bg-accent-100 hover:bg-accent-200 text-accent-700 border-r border-gray-300 dark:bg-gray-700 dark:text-accent-300 dark:border-gray-600 dark:hover:bg-gray-600"
            @click.stop="() => { if (!fieldDisabled) return togglePopover()}"
          >
            <svg-icon
              class="icon w-5 h-5 focused:form-shadow-input-focus focused:form-ring focused-hover:form-shadow-input-hove form-radius-input form-h-input-height"
              type="mdi"
              :path="mdiCalendar"
              :size="28"
            ></svg-icon>
          </a>
          <input
            :value="inputValue"
            @click.stop="() => { if (!fieldDisabled) return togglePopover()}"
            class="flex-grow px-2 py-1 bg-white dark:bg-gray-700"
            :class="{'disabled': fieldDisabled}"
            :disabled="fieldDisabled"
            :readonly="fieldReadonly"
          />
        </div>
      </template>
    </DatePicker>
  </div>
</template>

<script>
import abstractField from "./abstractField";
import { DatePicker } from "v-calendar";
import "v-calendar/dist/style.css";
import SvgIcon from "@jamescoyle/vue-icon";
import { mdiCalendar } from "@mdi/js";

export default {
  name: "DateTimeField",
  components: {
    DatePicker,
    SvgIcon,
  },
  props: {   
  },
  mixins: [abstractField],
  data() {
    return {
      options: [],
      date: new Date(),
      mdiCalendar: mdiCalendar,
      masks :{
        modelValue: 'YYYY-MM-DDTHH:mm:ss',
      }
    };
  },
  computed: {
    hasValue() {
      return this.value && this.value?.length > 0 ? true : false;
    },
  },
  methods: {
    cLearValue() {
      console.log("cLearValue");
      this.value = "";
    },
  },
};
</script>

<style scoped lang="scss">
.disabled {
    background-color: #e2e8f0;
    border-color: #e2e8f0;
    cursor: default;

    &:hover {
       border-color: #e2e8f0;
    }  
    &:focus-visible {
      border-color: #e2e8f0;
    }
  }
</style>
