import { fileOpen} from "browser-fs-access";
// import fileApi from "@/api/filesApi"
export async function openFile() {
  
  return await fileOpen({
    multiple: true,
    excludeAcceptAllOption: true,
  });  
  // let fileVsData = await readSingleFile(files[0]);
  // // let res = await fileApi.uploadFile(fileVsData);
  // console.log(fileVsData);
  // return fileVsData;
}

// async function readSingleFile(file) {
//   const toBase64 = (file) =>
//     new Promise((resolve, reject) => {
//       const reader = new FileReader();
//       reader.readAsDataURL(file);
//       reader.onload = () => resolve(reader.result);
//       reader.onerror = (error) => reject(error);
//     });
//   console.log(file);
//   const fileToBlob = async (file) =>
//     new Blob([new Uint8Array(await file.arrayBuffer())], { type: file.type });  
//   let newFile = {
//     name: file.name,
//     type: file?.type,
//     lastModified: file.lastModified,
//     data: await fileToBlob(file),
//   };
//   return newFile;
// }
